import React from "react";
import theme from "theme";
import { Theme, Link, Image, Em, Strong, Text, LinkBox, Section, Span, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Dropify.net for Creators Becoming Founders
			</title>
			<meta name={"description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:title"} content={"Dropify.net for Creators Becoming Founders"} />
			<meta property={"og:description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/cover.png?v=2021-12-12T04:10:19.294Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
		</Helmet>
		<Section
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Stack md-justify-content="space-between">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem width="50%" display="block" quarkly-title="Menu" md-width="25%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					{"        "}
					<Components.BurgerMenu>
						<Override
							slot="menu"
							lg-transform="translateY(0px) translateX(0px)"
							lg-transition="transform 400ms ease 0s"
							md-position="fixed"
							display="flex"
							md-width="100%"
							padding="0px 0 0px 0"
							md-left={0}
							md-top={0}
							md-height="100%"
						>
							<Override slot="item-404" lg-display="none" display="none" />
							<Override
								slot="link"
								color="--dark"
								hover-opacity="1"
								md-font="16px/24px sans-serif"
								font="--base"
								text-decoration-line="initial"
								text-transform="initial"
								md-opacity=".5"
								md-transition="opacity .15s ease 0s"
								md-active-opacity="1"
								md-color="--dark"
								transition="opacity .15s ease 0s"
								letter-spacing="0.5px"
								md-hover-opacity="1"
								opacity=".5"
							/>
							<Override slot="link-about" />
							<Override slot="link-team" />
							<Override slot="link-contact" />
							<Override
								slot="item"
								text-transform="uppercase"
								text-align="center"
								padding="8px 20px 8px 20px"
								md-padding="16px 40px 16px 40px"
								display="inline-block"
							/>
							<Override slot="item-index" lg-display="none" display="none" />
							<Override
								slot="link-active"
								md-opacity="1"
								md-cursor="default"
								opacity="1"
								color="--primary"
								cursor="default"
							/>
							<Override slot="link-work" />
							<Override slot="item-product" display="none" />
						</Override>
						<Override slot="icon,icon-close" category="md" icon={MdMenu} />
						<Override slot="icon" category="md" icon={MdMenu} size="36px" />
						<Override
							slot="menu-open"
							md-top={0}
							md-bottom={0}
							md-display="flex"
							md-flex-direction="column"
							md-align-items="center"
							md-justify-content="center"
						/>
						<Override slot="icon-open" md-position="fixed" md-top="24px" md-right="calc(4% + 4px)" />
					</Components.BurgerMenu>
					{"    "}
				</StackItem>
				<StackItem width="25%" md-display="none" display="flex" quarkly-title="Side">
					<Override slot="StackItemContent" align-items="center" justify-content="flex-end" />
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0" border-style="dotte">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Services for Creators
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://images.unsplash.com/photo-1552664688-cf412ec27db2?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						width="320px"
						max-width="100%"
						display="inline-block"
						border-width="2px"
						border-radius="24px"
					/>
					<Text margin="0px 0px 0px 0px" font="--headline3">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							sm-font="--headline3"
						>
							Product Management{" "}
						</Span>
					</Text>
				</Box>
				<Box padding="10px">
					<Image src="https://images.unsplash.com/photo-1599420186946-7b6fb4e297f0?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000" width="320px" max-width="100%" border-radius="24px" />
					<Text margin="0px 0px 0px 0px" font="--headline3">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							sm-font="--headline3"
						>
							Product Design
						</Span>
					</Text>
				</Box>
				<Box padding="10px">
					<Image src="https://images.unsplash.com/photo-1634838080334-28befa9efe80?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000" width="320px" max-width="100%" border-radius="24px" />
					<Text margin="0px 0px 0px 0px" font="--headline3">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								sm-font="--headline3"
							>
								Product Development
							</Span>
						</Span>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				min-width="100px"
				min-height="100px"
				lg-display="grid"
				display="grid"
				grid-template-columns="1fr 1fr"
				lg-grid-template-columns="1fr"
				lg-justify-items="center"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 0px"
					justify-content="center"
					lg-order="0"
					lg-padding="16px 0 16px 0px"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--dark"
						font="--headline1"
						lg-text-align="center"
						sm-font="--headline2"
					>
						We are transforming creators into product creators.
					</Text>
					<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
						Currently 77% of revenue for creators come from brand deals (CB Insights). Brands want what they don't have, your audience.{" "}
						<br />
						<br />
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							We help you discover the products (app, platform, SaaS) your audience actually wants, then build it. While you continue to make content, we take care of the rest, and you take home{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								passive income
							</Strong>
							. Give yourself options to create the future you want, independently.{" "}
						</Span>
						<br />
						<br />
						You can get started for{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							free
						</Strong>
						! We only earn income when you earn income.
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" text-align="center" padding="0px 0px 0px 0">
						Contact Us{" "}
					</Text>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 0px 0px" font="--base">
							By partnering with us you agree to our Terms of Use and Privacy Policy
						</Text>
						<Components.EmbedHtmljs customHtml="<iframe src=&quot;https://coda.io/embed/H1ZHBSfSQV&quot; width=500 height=600 frameBorder=&quot;0&quot; style=&quot;max-width: 100%;&quot; allow=&quot;fullscreen&quot;></iframe>" />
					</Box>
				</Box>
				<Image
					src="https://images.unsplash.com/photo-1623162905251-e98a25a45a67?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					object-fit="cover"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					lg-display="flex"
					display="flex"
					sm-display="flex"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack xl-justify-content="center" justify-content="center">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem display="flex" quarkly-title="Logo" md-width="50%" width="50%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					<Link
						text-decoration-line="initial"
						opacity="0.6"
						font="--base"
						href="/terms"
						color="#000000"
					>
						Terms of Use & Privacy Policy
					</Link>
					{"        "}
				</StackItem>
				<StackItem
					md-width="100%"
					display="flex"
					quarkly-title="Side"
					width="25%"
					md-display="block"
					sm-display="block"
				>
					<Override
						slot="StackItemContent"
						align-items="center"
						justify-content="flex-end"
						flex-direction="row"
						md-justify-content="center"
						sm-justify-content="center"
					/>
					<Section padding="0px 0 0px 0">
						<SocialMedia twitter="https://twitter.com/dropifyxyz" discord="https://discord.gg/ayC8tt24Cd">
							<Override
								slot="link"
								border-radius="50%"
								color="--light"
								margin="0 8px"
								background="--color-grey"
								hover-background="--color-greyD1"
							/>
						</SocialMedia>
					</Section>
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script async={true} place={"endOfHead"} rawKey={"61b58c1050b03822215b2eac"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MV36QFQ');"}
			</script>
			<script place={"endOfHead"} rawKey={"6246a896ffca0fbab6cc4994"}>
				{"document.addEventListener(\"DOMContentLoaded\",(e)=>{const ttclid=new URLSearchParams(window.location.search).get('ttclid');if(localStorage.getItem('ttclid')&&!ttclid){return}localStorage.setItem('ttclid',ttclid)})"}
			</script>
		</RawHtml>
	</Theme>;
});